angular.module('fingerink')
    .service('viewTemplateModal', function ($uibModal) {
        this.modalInstance = null;
        this.openModal = function (firmantes, plantilla) {
            this.modalInstance = $uibModal.open({
                backdrop: 'static',
                templateUrl: 'modals/viewTemplateModal.tpl.html',
                controller: 'viewTemplateModalCtrl',
                controllerAs: 'controller',
                animation: false,
                size: 'lg',
                resolve: {
                    firmantes: function () {
                        return firmantes;
                    },
                    plantilla: function () {
                        return plantilla;
                    }
                }
            });

            return this.modalInstance;
        };

    })
    .controller('viewTemplateModalCtrl', function viewTemplateModalCtrl($scope, session, templateService, $stateParams, $state, $window, $uibModalInstance, plantilla, $translate) {
        var that = this;

        that.plantilla = plantilla;
        that.pageURLS = [];
        that.loading = true;
        that.firmantesId = [];
        templateService.getPlantilla(plantilla.id).then(function (response) {
            that.plantilla = response.data;

            for (var k = 0; k < that.plantilla.signers.length; k++) {
                that.firmantesId[that.plantilla.signers[k].id] = that.plantilla.signers[k];
            }

            that.pageURLS = [];
            var retrieveFPageURL = function (i) {
                templateService.getURL(that.plantilla.id, i).then(function (response) {
                    that.pageURLS[i] = response.data;
                });
            };
            for (var jF = 0; jF < that.plantilla.pages; jF++) {
                retrieveFPageURL(jF);
            }

        });
        that.close = function () {
            $uibModalInstance.close(that.widgets);
        };

    });
